body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Raleway', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #232323 !important;
  font-size: 16px !important;
  line-height: 30px;
}

code {
  font-family: "Raleway" sans-serif;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  text-transform: capitalize;
}

/*helping claass*/

h2 {
  font-weight: 500!important;
  line-height: 35px;
  color: #004d80;

}
p{
  color: #626262;
}
h1 {
  line-height: 55px;
}
.h4, h4 {
  font-size: 1.3rem !important;
  line-height: 1.3 !important;
}
.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-60 {
  margin-top: 60px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-60 {
  padding-top: 60px;
}

.box-md-1 {
  width: 8.333333333333333%;
  padding: .75rem;
}

.box-md-2 {
  width: 16.66666666666667%;
  padding: .75rem;
}

.box-md-3 {
  width: 25%;
  padding: .75rem;
}

.box-md-4 {
  width: 33.33333333333333%;
  padding: .75rem;
}

.box-md-5 {
  width: 41.66666666666667%;
  padding: .75rem;
}

.box-md-6 {
  width: 50%;
  padding: .75rem;
}

.box-md-7 {
  width: 58.33333333333333%;
  padding: .75rem;
}

.box-md-8 {
  width: 66.66666666666666%;
  padding: .75rem;
}

.box-md-9 {
  width: 75%;
  padding: .75rem;
}

.box-md-10 {
  width: 83.33333333333333%;
  padding: .75rem;
}

.box-md-11 {
  width: 91.66666666666666%;
  padding: .75rem;
}

.box-md-12 {
  width: 100%;
  padding: .75rem;
}
.bar.bar-big {
  height: 5px;
  width: 90px;
  background: #004d80;
  margin: 20px auto;
  position: relative;
  border-radius: 30px;
}
/* navbar css */
.top-header a.active:nth-child(1){
  margin-left: 0px;
}
.active-nav {
  border-radius: 20px;
  box-shadow: 0px 0px 5px 0px white;
}
.top-header {
  background: #184c80;
  padding: 10px 0px;
}
.top-header a{
  color: #fff;
  text-decoration: none;
  
  margin-right: 20px;
  text-transform: initial;
  font-size: 13px;
}
.navbar-brand h2{
  color: #184c80;
  margin-bottom: 0;
}
.navbar-brand p{
  font-size: 10px;
  margin-bottom: 0;
  color: #ffa411;
  font-weight: 700;
}
.button {
  background-color: #184c80;
  color: #fff;
  padding: 10px 30px;
  text-align: center;
  text-decoration: none;
  font-size: 1.5rem;
  border-radius: 5px;
}

.animation-content p {
  color: #626262;
  font-size: 18px;
  line-height: 35px;
  font-style: italic;
}
.project-slider .slick-slide a.nav-link {
  height: auto;
  overflow: hidden;
  position: relative;
}
.footer-logo {
  width: 140px;
}
.project-slider .slick-slide a.btn-custom {
  margin: auto;
  background: #004d80;
  height: 35px;
  width: 125px;
  color: #fff;
  box-shadow: 0px 10px 30px 0px rgba(133, 66, 189, 0.1);
  font-size: 16px;
  border-radius: 5px;
  margin-bottom: 13px;
  display: block;
  transform: skewX(352deg);
}
.navbar {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  justify-content: center;
  align-items: center;
  padding: 1%;
  background-color: rgb(12 90 219 / 83%);
  color: white;
  position: sticky;
  top: 1px;
  z-index: 99;
  transition: 1s;

}
.footer a{
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  text-transform: initial;
}
.footer p{
  color:#f5f5f5;
}
.footer h5{
  font-size: 16px;
}
.footer .container .col-md-3 svg {
  height: 3em !important;
  width: 3em;
  background: #470567;
  padding: 10px;
  border-radius: 10px;
  margin-left: 5px;
  margin-right: 5px;
}
.profile-logo {
  width: fit-content;
  margin: auto;
}

.nav-items-container {
  width: fit-content;
  margin: auto;
}

.nav-items-container-mobile {
  display: none;
  position: sticky;
  top: 65px;
  background-color: rgb(7 158 236);
  z-index: 9;
}

.nav-items-container ul {
  display: flex;
  gap: 25px;
  list-style: none;
}

.nav-items-container ul a,
.nav-items-container-mobile ul a {
  width: max-content;
  text-decoration: none;
  color: white;
  text-transform: capitalize;
}
.custom-menu a {
  text-decoration: none;
  color: #232323;
  margin: 5px 15px;
  padding: 10px;
  font-weight: 600;
}
a.social svg {
  height: 3em !important;
    width: 3em;
    background: #470567;
    padding: 10px;
    border-radius: 10px;
    margin-left: 5px;
    margin-right: 5px;
    color: #fff;
}
a.navbar-brand {
  width: 120px;
}
.custom-menu a:nth-last-child(1){
  margin-right: 0px;
}
.custom-menu a:hover {
  color: #0079e0;
}
.nav-items-container ul li {
  cursor: pointer;
  padding: 10px 10px;
  border-radius: 20px;
}

.nav-items-container ul li:hover {
  box-shadow: 0px 0px 5px 0px white;
  color: white;
}

.contact-corner {
  display: flex;
  width: max-content;
  margin: auto;
  align-items: center;
  gap: 30px;
}

.mobile-menu-grid {
  display: none;
}

@media screen and (max-width:1100px) {
  .mobile-menu-grid {
    display: inline;
  }

  .navbar {
    grid-template-columns: 1fr 1fr;
  }

  .nav-items-container {
    display: none;
  }

  .nav-items-container-mobile {
    display: none;
    width: 100%;
    margin: 30px auto;
    padding: 2%;
  }

  .nav-items-responsive {
    display: block;
  }

  .nav-items-responsive li {
    margin: 2px 0px;
    background-color: aliceblue;
    border-radius: 5px;
  }

  .nav-items-container-mobile ul {
    list-style: none;
  }

  .nav-items-container-mobile ul li {
    color: #000;
  }
}


/* Home */
.animation-content h1 {
  font-size: 2.7rem;
  color: #004d80;
}

/* slider */
.slider-container {
  background-image: url("../src/Images/visionary_technology.svg");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: right;
  padding: 1%;
  /* width: 100%; */
  height: 452px;
  overflow: hidden;
}

.slider {
  display: flex;
  flex-wrap: nowrap;
  width: 100% !important;
  color: #232323;
  overflow: hidden;
}

.slider-pages {
  min-width: 100%;
  transition: 1s;
  padding: 2% 4%;
}

.animation-container {
  width: 100%;
  overflow: hidden;
}

.animation-content {
  /* position: absolute; */
  transition: 0.5s;
  animation: animateHome 1s 0s 1 linear;
  width: 60%;

}

@keyframes animateHome {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0%);
  }
}

.slider-bars {
  color: white;
  display: flex;
  justify-content: center;
  gap: 15px;
}

.slider-bars hr {
  height: 6px;
  width: 85px;

}

.active-hr {
  background-color: gray;
}

.buttons {
  width: max-content;
  display: flex;
  flex-wrap: nowrap;
  transform: skewX(345deg);
  padding: 1%;
}

.buttons button {
  border-radius: 0px;
  border-radius: 0px;
  width: 110px;
  padding: 5%;
  background-image: linear-gradient(-90deg, black, transparent);
}


/* ABOUT */

.about-main-container {
  width: 100%;
  overflow: hidden;
  background: #f1ebeb;
}

.disclaimer {
  width: fit-content;
  margin: 40px auto;
}



.postedby {
  color: #616161 !important;
}

span.icons svg {
  font-size: 90px;
}
.detail-about-us .details {
  box-shadow: 0px 10px 30px 0px rgba(133, 66, 189, 0.1);
  border-radius: 5px;
  padding: 15px 20px;
  cursor: pointer;
  transition: 0.5s;
  background: #fff;
  min-height: 35vh;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.icons svg {
  height: 100px;
  width: 100px;
  color: #004d80;
}
.icon svg {
  height: 50px;
  width: 50px;
  color: #004d80;
}
.detail-about-us .details h2 {
  /* text-align: center; */
  font-size: larger;
  margin-bottom: .5rem;
}

.about-second-page {
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  /* background-image: url(../public/Images/call-do-bg.png);
  background-repeat: no-repeat;
  background-position: center; */
  background-size: cover;
  color: #232323;
  justify-content: space-around;
  align-items: center;
}

section.contact-form {
  box-shadow: 0px 10px 30px 0px rgba(133, 66, 189, 0.1);
  padding: 15px;
}
.contact-details{
  background-color: #00003a;
  border-radius: 5px;
  color: #fff;
  margin-left: 20px;
  display: flex;
  align-items: center;
}
a.contactdetails svg{
    height: 3em !important;
    width: 3em;
    background: #470567;
    padding: 10px;
    border-radius: 10px;
    margin-left: 5px;
    margin-right: 5px;
    color: #fff;
}
a.contactdetails {
  color: #fff !important;
}
.contact-first-page p a {
  color: #232323;
  text-transform: initial;
  font-size: 16px;
  text-decoration: none;
}
.contact-form button.btn.btn-primary {
  background: #004d80;
  border: 1px solid #004d80;
}
.image {
  height: auto;
  padding: 19px;
  margin: 30px auto;
}

.image img {
  border-radius: 0px 5%;
}
span.orange {
  color: #ffa411;
}
.detail-about-us-2 {
  margin: 30px auto;
}
span.quality {
  color: #004d80;
}
.detail-about-us-2 p:nth-child(1){
  width: fit-content;
    margin: auto;
    color: #004d80;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 4px;
    text-transform: uppercase;
}

/* Services */

.services-container {
  padding: 1%;
  width: 100%;
  overflow: hidden;
}
h4.title {
  height: 56px;
}
.bg-img {
  background: #c1c1c1;
  border: 1px solid #c1c1c1;
  border-bottom: 0;
  height: 30vh;
  overflow: hidden;
}
.services-detail {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 1% 0;
  row-gap: 25px;

}

.services-detail .details {
  padding: 15px 20px;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 10px 30px 0px rgba(133, 66, 189, 0.1);
  border-radius: 5px;
  min-height: 56vh;
}



.services-detail .details .icon {
  margin: 10px 0px;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.services-detail .details .icon i {
  font-size: 2.5rem;
  color: #004d80;
}

.services-detail .details .sequence-number {
  font-size: x-large;
  font-weight: 900;
  border-radius: 50%;
  background-color: #ff7c00;
  color: white;
  width: 110px;
  height: 110px;
  position: absolute;
  padding: 35px 19px;
  top: -34px;
  right: -34px;
  text-align: center;
}

.services-detail .details h2 {
  margin: auto;
  font-size: larger
}


/* PROJECTS */
.project-container {
  padding: 45px 15px;
  background-color: #f5f5f5;
  color: #181818;
  margin-bottom: 15px;
  width: 100%;
  overflow: hidden;
}

.project-box {
  display: flex;
  flex-wrap: nowrap;
  /* justify-content: space-around; */
  gap: 5%;
  padding: 1%;
  overflow: hidden;
  margin: 15px 0px;
}

.project {
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px white;
  position: relative;
  transition: 1s;
}

.content-container {
  position: relative;
}

.content-container>img {
  background-color: blue;

}

.project p {
  background-image: linear-gradient(-55deg, red, #0911e9);
  color: white;
  padding: 10px;
  width: fit-content;
}

.project strong {
  display: grid;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: black;
  width: 90%;
  height: 85px;
  /* margin: auto; */
  padding: 10px;
  font-size: larger;
}

.footer-content .active li {
  font-weight: bold;
  color: #007bff;
}

.nav-items-container .active li {
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px white;
}
h4.title a {
  color: #232323 !important;
}
.news-content a:hover{
  color: #007bff !important;
}
.news .show-more {
  width: max-content;
  border-bottom: 1px solid blue;
  color: blue;
}

@media screen and (max-width:768px) {
  .details-of-project {
    display: none;
  }

  .content-container {
    width: 100%;
  }
  .animation-content{
    width: 100%;
  }
  .animation-content h1 {
    font-size: 1.5rem;
}
.slider-container{
  background-image: none;
}
.icons {
  width: 100%;
}
}


/* CONTACT */

.contact-first-page {
  width: 100%; 
  display: flex;  
  justify-content: space-between;
  padding: 5% 0%;
  
}

.contact-input-container {
  display: grid;
  grid-template-columns: 45% 45%;
  padding: 20px 1%;
  gap: 1%;
}

@media screen and (max-width:850px) {
  .contact-first-page {
    grid-template-columns: 100%;
    padding: 10%;    
    flex-wrap: wrap;
  
  }
  .contact-details{
    margin-top: 25px;
  }
  .contact-input-container {
    display: grid;
    grid-template-columns: 90%;
  }
}


/* Strategies */


.strategy-container {
  row-gap: 30px;
  justify-content: center;
}

.strategies {
  border-radius: 4px;
  cursor: pointer;
  transition: 0.5s;
  background-color: white;
  text-align: center;
}

.strategies .icon {
  margin: 10px auto;
  width: 110px;
  height: 110px;
  background-color: #e9e9e9;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* FOOTER */
.footer {
  background: url(../public/Images/footer-threee.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: #fff;
  padding: 2%;
}

.footer .nav-items {
  margin: 5px 0px;
}

.footer .nav-items:hover {
  color: #fbc051;
}

.footer-container {
  display: flex;
  justify-content: center;
  align-items: baseline;
  flex-wrap: wrap;
  gap: 3%;
}

.footer-content {
  width: fit-content;
  padding: 1%;
  min-width: 300px;
  margin: 20px 0px;
}

.footer-content2 {
  width: 50%;
  padding: 1%;
  min-width: 310px;
}

.footer-content ul {
  list-style: none;
  width: fit-content;
}

.footer-content ul a {
  text-decoration: none;
}


/* CARRIER */
.carriers {
  width: 100%;
  height: max-content;
  padding: 50px;
  width: 100%;
  overflow: hidden;
}

.carrier-details {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10%;
}

.carrier {
  width: 150px !important;
  height: 150px !important;
  margin: auto;
  border-radius: 50%;
  box-shadow: 0px 0px 25px 0px white;
  display: grid;
  justify-content: center;
  align-items: center;
  margin: 20px 0px;
}

.carrier+p {
  text-align: center;
}

.reviews {  
  overflow: hidden;
}

.review-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  gap: 2%;
  padding: 1%;
  /* animation: animatereview 15s infinite linear; */
}

.review-container:active {
  animation: animatereview 15s 0 linear;
}

.review {
  /* min-width: 300px !important;
  height: 300px !important;
  box-shadow: 0px 0px 5px 0px #060606; */
  background-color: #f8f9fa;
  border-radius: 5px;
  padding: 1% 2%;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: 0.5s;
  margin: 20px 0px;
  color: black;

}
.review .disclaimer p{
  letter-spacing: normal;
  color: #626262;
  text-transform: none;
  font-size: 15px;
  font-weight: 400;
}
.disclaimer p {
  color: #004d80;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 4px;
  margin: auto;
  text-transform: uppercase;
}

.client-image {
  width: 100px;
  height: 100px;
  margin: auto;
  overflow: hidden;
  border-radius: 50px;
  border: 4px solid #fff;
}

.slick-track {
  display: flex !important;
  justify-content: space-between;
}

.slick-slide {
  overflow: hidden;
  background: #fff;
  border-radius: 5px;
  margin: 10px;
  text-align: -webkit-center;
}
.project-slider .slick-slide{
  display: flex !important;
    justify-content: center;
    align-items: center;
    height: 110px;
}
.news-container .slick-slide {
  border: 0px;
  border-radius: 0px;
}
.news-data {
  min-height: 45vh;
}
.slick-slide a.nav-link {
  height: 250px;
}
.slick-slide .news-content a.nav-link {
  height: auto;
  overflow: hidden;  
  text-overflow: ellipsis;
  white-space: nowrap;
}
.blog-container {
  background: #fff;
  margin-bottom: 30px;
}
.slick-slider .slick-prev {
  left: 9px;
}

.slick-slider .slick-next {
  right: 9px;
}

.slick-slider .slick-prev,
.slick-slider .slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 40px;
  height: 40px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: #b77b7b;
  border: none;
  outline: none;
  background: black;
  z-index: 99;
}

@keyframes animatereview {
  from {
    transform: translateX(110%);
  }

  to {
    transform: translateX(-110%);
  }
}

/* animations */
.animateDetailAbout {
  animation: animatedetailabout 1s 1 linear;
  background-color: #000;
}

@keyframes animatedetailabout {
  from {
    transform: rotateX(180deg)
  }

  to {
    transform: rotateX(0deg);
  }
}


/* NEWS */
.news-main-container {
  width: 100%;
  overflow: hidden;
  padding: 0% 1% 1% 1%;
  background-color: white;
}

.news-main-container p,
.news-main-container h2 {
  color: black;
}

.news-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  gap: 5%;
  width: 100%;
}

.news {
  min-width: 310px !important;
  height: max-content;
  background: #272628;
  position: relative;
  margin-bottom: 80px;

}



.news-content {   
   border: 1px solid #cfcaca;
  background-color: white;
  padding: 8px;
  min-height: 45vh;
}

.news .writter-img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #000;
  transform: translate(5px, -50px);
  margin-bottom: -40px;

}

.news .name {
  font-weight: 100;
}

.news .title {
  font-weight: bolder;
}

.news .comments-container {
  font-weight: bold;
  padding: 10px;
  cursor: pointer;
}

.comment {
  font-weight: 400;
}